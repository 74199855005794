import React, { Component } from 'react';
import Helmet from 'react-helmet';
import { debounce } from 'lodash';
import classnames from 'classnames';
import { imgBrand, isBe } from '../../lib/brand';

var defaultLogoBlack = imgBrand(isBe ? 'be_logo_black.png' : 'rf_logo_black.png', 'Header');
var defaultLogoWhite = imgBrand(isBe ? 'be_logo_white.png' : 'rf_logo_white.png', 'Header');
var defaultLogoShadow = imgBrand(isBe ? 'be_logo_shadow.png' : 'rf_logo_shadow.png', 'Header');

import styles from '../Header/styles.module.less';

import dbaForbidden from '../Header/lib/dbaForbidden';
import {
   getUuidFromUrl,
   createDbaLogoShadow,
   createDbaLogo,
   createWhiteDbaLogo,
   getCropParamfromUrl
} from '../../lib/ucImage';
import hasLocalStorage from '../../lib/hasLocalStorage';

import { headerNav } from '../../lib/gtm';

const dbaBlackStore = 'be.dba.logo.black';

const home = '/';

export default class HeaderSeniorLending extends Component {
   constructor(props) {
      super(props);
      this.debouncedHandleScroll = debounce(this.handleScroll, 100, {
         leading: true
      }).bind(this);
      const { dbaBlack } = this.props;
      // set dba logo state as soon as possible
      if (dbaBlack) {
         this.setState({
            dbaBlack
         });
      } else {
         if (typeof window !== 'undefined' && hasLocalStorage()) {
            this.getDba();
         }
      }
   }

   static defaultProps = {
      active: [],
      theme: 'default',
      dbaBlack: null,
      clearDba: false
   };

   state = {
      scrolled: false,
      isOpen: false,
      dbaBlack: null,
      mounted: false
   };

   componentDidMount() {
      const { dbaBlack, clearDba } = this.props;
      window.addEventListener('scroll', this.debouncedHandleScroll);

      // reset dba on specific pages - branch, lo, team, search
      if (clearDba) {
         this.unSetDba();
      }
      // if dba logo provided, set cookie and state
      if (dbaBlack) {
         this.setDba(dbaBlack);
      } else {
         // fallback and get dba from state or storage if available
         this.getDba();
      }
      this.setState({ mounted: true });
   }

   componentWillUnmount() {
      window.removeEventListener('scroll', this.debouncedHandleScroll);
   }

   toggle = e => {
      e.preventDefault();
      const { isOpen } = this.state;
      this.setState({ isOpen: !isOpen });
   };

   handleScroll = e => {
      let scrolled = false;
      if (window && window.scrollY) {
         scrolled = window.scrollY > 0;
      }
      this.setState({ scrolled });
   };

   /**
    * Set DBA logos in local storage and in state
    */
   setDba = dbaBlack => {
      if (hasLocalStorage()) {
         window.localStorage.setItem(dbaBlackStore, dbaBlack);
      } else {
         console.warn('No local storage');
      }
      this.setState({
         dbaBlack
      });
   };

   /**
    * Get DBA Logos from local storage and set in state
    */
   getDba = () => {
      if (hasLocalStorage()) {
         let dbaBlack = window.localStorage.getItem(dbaBlackStore);
         this.setState({
            dbaBlack
         });
      } else {
         console.warn('No local storage');
      }
   };

   /**
    * Remove DBA Logos from local storage and remove from state
    */
   unSetDba = () => {
      if (hasLocalStorage()) {
         let storage = window.localStorage;
         storage.removeItem(dbaBlackStore);
         this.setState({
            dbaBlack: null
         });
      } else {
         console.warn('No local storage');
      }
   };

   activate = links => {
      const { active } = this.props;
      if (!Array.isArray(active)) return links;
      const updateLink = link => {
         const { slug, children } = link;
         link.active = active.includes(slug);
         if (Array.isArray(children)) {
            link.children = children.map(updateLink);
         }
         return link;
      };
      return links.map(updateLink);
   };

   renderLink(link, isChild = false) {
      const { name, slug, active, children = [] } = link;
      let runts = Array.isArray(children) ? children : [];
      let cls = classnames({
         [styles.active]: !!active,
         [styles.child]: isChild,
         [styles.parent]: !isChild
      });

      return (
         <li key={name} className={cls}>
            <a href={`/${slug}/`} data-gtm={headerNav}>
               {name}
            </a>
            <ul className={styles.children}>
               {runts.map((child, i) => {
                  return this.renderLink(child, true);
               })}
            </ul>
         </li>
      );
   }

   renderStyles = () => {
      // get dba logos from props
      let { dbaBlack, active } = this.props;
      // if forbidden - we show BE logo
      const isDbaForbidden = dbaForbidden(active);

      // set default BE logo image
      let logoBlackUrl = defaultLogoBlack;
      let logoWhiteUrl = defaultLogoWhite;
      let logoShadowUrl = defaultLogoShadow;

      // fall back to dba logos from state
      if (!dbaBlack) {
         dbaBlack = this.state.dbaBlack;
      }

      // create formatted dba URLs, preserving cropping if present
      if (dbaBlack && !isDbaForbidden) {
         logoBlackUrl = createDbaLogo(getUuidFromUrl(dbaBlack), getCropParamfromUrl(dbaBlack));
         logoWhiteUrl = createWhiteDbaLogo(getUuidFromUrl(dbaBlack), getCropParamfromUrl(dbaBlack));
         logoShadowUrl = createDbaLogoShadow(getUuidFromUrl(dbaBlack), getCropParamfromUrl(dbaBlack));
      }

      let rules = `
        .${styles.Header} .${styles.container} .${styles.logo} {
            background-image: url("${logoBlackUrl}") !important;
        }

        [data-theme="blue-ribbon"].${styles.Header} .${styles.container} .${styles.logo} {
            background-image: url("${logoWhiteUrl}") !important;
        }

        @media screen and (min-width: 1024px) {
            [data-theme="light"].${styles.Header}:not(:hover) .${styles.container} .${styles.logo} {
                background-image: url("${logoWhiteUrl}"), url("${logoShadowUrl}") !important;
                background-size: contain, contain;
                background-position-y: center, center;
                background-position-x: right, right;
            }
    
            [data-theme="light"].${styles.Header}.${styles.scrolled} .${styles.container} .${styles.logo},
            [data-theme="light"].${styles.Header}:hover .${styles.container} .${styles.logo} {
                background-image: url("${logoBlackUrl}") !important;
            }
        }
    `;

      return <style>{rules}</style>;
   };

   render() {
      const { theme } = this.props;
      const { isOpen, scrolled, mounted } = this.state;

      let openState = !!isOpen ? 'open' : 'closed';

      let cls = classnames(styles.Header, { [styles.scrolled]: scrolled });

      return (
         <header className={cls} data-theme={theme} data-state={openState}>
            <Helmet>{this.renderStyles()}</Helmet>
            <div className={styles.container}>
               <div hidden={!mounted} className={styles.logo}>
                  <a href={home} data-gtm={headerNav}>
                     <span>Bay Equity Home Loans</span>
                  </a>
               </div>
            </div>
         </header>
      );
   }
}
